export const Paths = {
  Home: '/',
  HowItWorks: '/how-it-works',
  Cost: '/cost',
  UserGuide: '/user-guide',
  CaseStudies: '/case-studies',
  Faq: '/faq',
  SignIn: '/sign-in',
  SignUp: '/sign-up',
  PrivacyPolicy: '/privacy-policy',
  TermsOfUse: '/terms-of-use',
  Contact: '/contact',
  ClerksGuide: '/clerks-guide',
  Case1Guide: '/case-1-guide',
  Case2Guide: '/case-2-guide',
  Case3Guide: '/case-3-guide',
  RequestVerification: '/request-verification',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
};
