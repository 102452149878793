import ActionTypes from '../lib/actionTypes';
import { PayloadAction } from '../lib/interfaces';
import { onFulfill, onPending, onReject } from '../lib/utils/actions';

export interface MeState {
  isLoading: boolean;
  me: Me | null;
}

export interface Me {
  caseCount: number;
  email: string;
  id: number;
  emailVerified: boolean;
}

interface MePayload {
  user: {
    case_count: number;
    email: string;
    id: number;
    email_verified: boolean;
  };
}

const initialState = {
  isLoading: false,
  me: null,
};

export function me(
  state: MeState = initialState,
  action: PayloadAction<MePayload> = {} as PayloadAction<MePayload>
): MeState {
  switch (action.type) {
    case onPending(ActionTypes.LOGIN):
    case onPending(ActionTypes.SESSION):
    case onPending(ActionTypes.LOGOUT):
    case onPending(ActionTypes.CREATE_USER):
    case onPending(ActionTypes.REQUEST_VERIFICATION):
    case onPending(ActionTypes.FORGOT_PASSWORD):
    case onPending(ActionTypes.RESET_PASSWORD):
      return {
        ...state,
        isLoading: true,
      };

    case onReject(ActionTypes.LOGIN):
    case onReject(ActionTypes.SESSION):
    case onReject(ActionTypes.LOGOUT):
    case onReject(ActionTypes.CREATE_USER):
    case onReject(ActionTypes.REQUEST_VERIFICATION):
    case onReject(ActionTypes.FORGOT_PASSWORD):
    case onReject(ActionTypes.RESET_PASSWORD):
    case onFulfill(ActionTypes.CREATE_USER):
    case onFulfill(ActionTypes.REQUEST_VERIFICATION):
    case onFulfill(ActionTypes.FORGOT_PASSWORD):
    case onFulfill(ActionTypes.RESET_PASSWORD):
      return {
        ...state,
        isLoading: false,
      };

    case onFulfill(ActionTypes.LOGOUT):
      return {
        ...initialState,
      };

    case onFulfill(ActionTypes.SESSION):
    case onFulfill(ActionTypes.LOGIN):
      return {
        ...state,
        me: {
          caseCount: action.payload!.user.case_count,
          email: action.payload!.user.email,
          id: action.payload!.user.id,
          emailVerified: action.payload!.user.email_verified,
        },
        isLoading: false,
      };

    default:
      return state;
  }
}
