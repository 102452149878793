/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'rc-drawer/assets/index.css';
import 'react-tippy/dist/tippy.css';
import '@szhsin/react-menu/dist/index.css';

import './src/tailwind.generated.css';
import './src/index.css';

import wrapWithProvider from './src/wrap-with-provider';

export const wrapRootElement = wrapWithProvider;
