import { Records } from '../forms/UploadForm/types';
import ActionTypes from '../lib/actionTypes';
import { PayloadAction } from '../lib/interfaces';
import { onFulfill, onPending } from '../lib/utils/actions';

export interface DocumentsState {
  totalCases: number;
  isLoading: boolean;
  tmpRecords: Records;
}

interface TotalCasesPayload {
  total_cases: number;
}

const initialState = {
  isLoading: false,
  totalCases: 0,
  tmpRecords: null,
};

export function documents(
  state: DocumentsState = initialState,
  action: PayloadAction<TotalCasesPayload | Records> = {} as PayloadAction<TotalCasesPayload>
): DocumentsState {
  switch (action.type) {
    case onPending(ActionTypes.GET_TOTAL_CASES):
      return {
        ...state,
        isLoading: true,
      };

    case onFulfill(ActionTypes.GET_TOTAL_CASES):
      return {
        ...state,
        totalCases: (action.payload as TotalCasesPayload)!.total_cases,
        isLoading: false,
      };

    case ActionTypes.SAVE_TEMPORARY_RECORD:
      return {
        ...state,
        tmpRecords: action.payload as Records,
      };
    default:
      return state;
  }
}
