import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import reducers from '../../reducers';
import { createMiddleware } from '../middleware';

const persistConfig = {
  key: 'hypercite.web',
  storage,
  whitelist: ['session', 'me'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store =
  process.env.NODE_ENV !== 'production'
    ? createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, ...createMiddleware())))
    : createStore(persistedReducer, applyMiddleware(thunk, ...createMiddleware()));
export const persistor = persistStore(store);
