// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-1-guide-tsx": () => import("./../../../src/pages/case-1-guide.tsx" /* webpackChunkName: "component---src-pages-case-1-guide-tsx" */),
  "component---src-pages-case-2-guide-tsx": () => import("./../../../src/pages/case-2-guide.tsx" /* webpackChunkName: "component---src-pages-case-2-guide-tsx" */),
  "component---src-pages-case-3-guide-tsx": () => import("./../../../src/pages/case-3-guide.tsx" /* webpackChunkName: "component---src-pages-case-3-guide-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-clerks-guide-tsx": () => import("./../../../src/pages/clerks-guide.tsx" /* webpackChunkName: "component---src-pages-clerks-guide-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cost-tsx": () => import("./../../../src/pages/cost.tsx" /* webpackChunkName: "component---src-pages-cost-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-request-verification-tsx": () => import("./../../../src/pages/request-verification.tsx" /* webpackChunkName: "component---src-pages-request-verification-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-user-guide-tsx": () => import("./../../../src/pages/user-guide.tsx" /* webpackChunkName: "component---src-pages-user-guide-tsx" */),
  "component---src-pages-verify-account-tsx": () => import("./../../../src/pages/verify-account.tsx" /* webpackChunkName: "component---src-pages-verify-account-tsx" */)
}

